var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "AddWageFIxed" },
    [
      _c(
        "Drawer",
        {
          attrs: {
            title: "新增薪资项字段",
            size: "small",
            placement: "right",
            closable: false,
            "is-mask": false,
            visible: _vm.visible,
          },
          on: { close: _vm.onClose, onSubmit: _vm.handleOk },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _vm._l(_vm.showList, function (item, index) {
                return [
                  _c(
                    "a-button",
                    {
                      key: "item" + index,
                      staticClass: "item",
                      attrs: { disabled: item.disabled },
                    },
                    [
                      _vm._v(" " + _vm._s(item.salaryItemName) + " "),
                      !item.disabled
                        ? _c(
                            "svg",
                            {
                              staticClass: "iconpark-icon",
                              on: {
                                click: function ($event) {
                                  return _vm.delAddItem(index)
                                },
                              },
                            },
                            [_c("use", { attrs: { href: "#close-small" } })]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              }),
              _c(
                "a-popover",
                {
                  attrs: {
                    trigger: "click",
                    placement: "bottom",
                    "get-popup-container": function (trigger) {
                      return trigger.parentNode
                    },
                  },
                  model: {
                    value: _vm.addVisible,
                    callback: function ($$v) {
                      _vm.addVisible = $$v
                    },
                    expression: "addVisible",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "content" },
                    [
                      _c("span", { staticClass: "salary-name" }, [
                        _vm._v("薪资项名称:"),
                      ]),
                      _c("a-input", {
                        staticClass: "input-salary",
                        attrs: {
                          "max-length": 10,
                          placeholder: "请填写薪资项名称",
                        },
                        model: {
                          value: _vm.addName,
                          callback: function ($$v) {
                            _vm.addName = $$v
                          },
                          expression: "addName",
                        },
                      }),
                      _c("br"),
                      _c(
                        "div",
                        { staticClass: "btn" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.add },
                            },
                            [_vm._v("确认")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("a-button", {
                    staticClass: "item",
                    attrs: { type: "iconBtn", icon: "add-one" },
                  }),
                ],
                2
              ),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }